import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SelfRegistrationValidationService } from 'src/app/core/services/self-registration-validation.service';
import { User } from '../../../../core/models/User';
import { UserValidation } from '../../../../core/models/UserValidation';
import { Subscription } from 'rxjs';
import { CustomSnackbarComponent } from '../../custom-snackbar/custom-snackbar.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConfigService } from 'src/app/core/services/app-config.service';

/** Class for  SelfRegistrationValidateUser */
@Component({
  selector: 'app-self-registration-validate-user',
  templateUrl: './self-registration-validate-user.component.html',
  styleUrls: ['./self-registration-validate-user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelfRegistrationValidationComponent implements OnInit, AfterViewInit {
  /** Input parameter userDetails received from parent component */
  @Input() userDetails: User;
  /** Input parameter step number received from parent component */
  @Input() step: number;
  /** Output parameter step number notified to parent component by emiting */
  @Output() notify: EventEmitter<number> = new EventEmitter<number>();
  /** Subscription property for subscribing and unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /** variable to store user validation details */
  userValidation: UserValidation = {} as UserValidation;
  /** variable for formgroup */
  regValidationForm: UntypedFormGroup;
  /** variable to have static content for phone OTP text */
  contentTextPrefix: string;
  /** variable to have static content text */
  contentTextPostfix: string;
  /** variable to have static content for email OTP text */
  contentEmailPrefix: string
  /** variable to store client contact name */
  clientContactName: string;
  /** variable to show static masked phone number text */
  phoneNumberHiddenChar: string;
  /** variable to store last 4 digits of phone number */
  PhoneNumberLastChars: string;
  /** variable to store the maskedemail address*/
  maskedEmail: string
  /** variable to have static otp text */
  otpTextPrefix: string;
  /** variable to have static otp text */
  optTextPostfix: string;
  /** variable to check no of invalid OTP attemps */
  invalidOTPCount = 0;
  /** variable to enable Continue button */
  isContinueBtnEnable = false;
  /** variable to check client is candidate or transferee*/
  isCandidateorTransferee: boolean;
  /** Used to hold toggle the phone/email otp display */
  showEmail: boolean = false
  /** Used to hold value whether otp toggle is present */
  otpToggle: boolean = false
  /** variable to to check OTP sent via Email or not */
  otpEmailSent: boolean = false
  /** variable to to check OTP sent via Phone or not */
  otpPhoneSent: boolean = false
  /** string constants to be displayed in self registration validation page */
  templateString = {
    PHONE_PLACEHOLDER_TEXT: 'Phone number provided:',
    emailPlaceholderText: 'Business Email Address provided:',
    SEND_CODE_BTN: 'Send Code',
    RESEND_CODE: 'Resend the Code',
    CONTINUE_BTN: 'Continue'
  };
  /** error constants to be displayed in self registration page */
  errorString = {
    INVALID_OTP: 'Invalid security code, please try again.',
    OTP_ATTEMPTS_EXCEEDED: 'Invalid security code. You have exceeded five attempts, please request for a new code.',
    INVALID_RESP: 'Unexpected error, please try again.'
  };

  /**
   * Base constructor
   * @param fb Formbuilder variable
   * @param spinner to get NgxSpinner
   * @param validationService SelfRegistrationValidationService
   * @param snackBar to get MatSnackBar
   */
  constructor(private readonly fb: UntypedFormBuilder,
    private readonly validationService: SelfRegistrationValidationService,
    // private readonly persistenceService: PersistenceService,
    public snackBar: MatSnackBar,
    public appConfig: AppConfigService,
    public ngxSpinner: NgxSpinnerService) 
    {
    this.contentTextPrefix = 'If you cannot receive text messages on this phone, please contact your representative at';
    this.contentTextPostfix = ' and provide them a suitable phone number that can. One message per registration attempt ' +
      'will be sent with a one time password to the number provided. Message and data rates may apply.';
    this.contentEmailPrefix = 'If you do not recognize this email address, please contact your representative at'
    this.clientContactName = 'Cartus';
    this.phoneNumberHiddenChar = '(***) *** - ';
    this.otpTextPrefix = 'You should receive a security code momentarily. If you do not, we can ';
    this.optTextPostfix = ' at your request. Once you receive a code, it will be valid for only 5 minutes before expiring.';

  }

  /** To Initialize Component */
  ngOnInit() {
    if (this.userDetails.roleName === 'candidate' || this.userDetails.roleName === 'transferee') {
      this.isCandidateorTransferee = true;
    } else {
      this.isCandidateorTransferee = false;
    }
    // otpToggle relies on the otpEmailAddess field to have a value returned
    if (this.userDetails.otpEmailAddress) {
      this.otpToggle = true
      const email = this.userDetails.otpEmailAddress
      const emailDomain = email.slice(email.indexOf("@"))
      const unmaskedUsername = email.slice(0, 2)
      const usernameLength = email.length - emailDomain.length
      const maskedChar = '*';
      if (usernameLength > 1) {
        const unmaskedUsernameLength = email.length - emailDomain.length
        const maskedPattern = `${maskedChar.repeat(unmaskedUsernameLength - 2)}`;
        this.maskedEmail = `${unmaskedUsername}${maskedPattern}${emailDomain}`
      } else {
        this.maskedEmail = `${maskedChar}${emailDomain}`
      }
    }
    if (this.userDetails?.isExistingUser) {
      if (this.userDetails.phoneNumber !== "undefined") {
        this.PhoneNumberLastChars = this.userDetails.phoneNumber.slice(this.userDetails.phoneNumber.length - 4);
      }else{
        this.otpToggle = false
        this.showEmail = true
      }
    } else {
      this.PhoneNumberLastChars = this.userDetails.phoneNumber.slice(this.userDetails.phoneNumber.length - 4);
    }
    this.createControl();
  }

  /** To Create form Controls */
  createControl() {
    this.regValidationForm = this.fb.group({
      otp: ['', Validators.required]
    });
  }

  /** Store the current step in session */
  ngAfterViewInit(): void {
    sessionStorage.setItem('currentStep', JSON.stringify(this.step));
  }

  /**
   * method to toggle process to send OTP to the user 
   * clears the form values, error and validator
   */
  toggleOTPMethod(): void {
    this.isContinueBtnEnable = false;
    this.showEmail = !this.showEmail
    this.regValidationForm.get('otp').setValue('');
    this.regValidationForm.get('otp').setErrors(null);
    this.regValidationForm.setErrors({ 'invalid': true });
  }

  /**
   * method to send OTP to the user
   * this.showEmail boolean specifies OTP via email or phone
   * @return A response containing the result (string)
   */
  sendOTP() {
    const user: User = {} as User;
    user.userId = this.userDetails.userId
    this.showEmail ? user.emailAddress = this.userDetails.otpEmailAddress : user.phoneNumber = this.userDetails.phoneNumber
    user.product = this.userDetails.product;
    user.systemOfOrigin = this.userDetails.systemOfOrigin;
    let openSnackbar: boolean = false
    if (!this.appConfig.getConfig('byPassOtp')) {
      this.subscription.add(
        this.validationService
          .sendOTP(user, true)
          .subscribe(response => {
            if (response === true) {
              user.emailAddress ? this.otpEmailSent = true : this.otpPhoneSent = true
            } else {
              openSnackbar = true
            }
          })
      );
    } else {
      user.emailAddress ? this.otpEmailSent = true : this.otpPhoneSent = true
    }

    if (openSnackbar) {
      this.snackBar.open(
        'We are unable to process your request at this time. Please try again later.',
        undefined,
        { duration: 5000 }
      );
    }
  }

  /** Method to resend OTP to the User */
  resendOTP() {
    this.isContinueBtnEnable = false;
    this.regValidationForm.get('otp').setValue('');
    this.regValidationForm.get('otp').setErrors(null);
    this.regValidationForm.setErrors({ 'invalid': true });
    this.invalidOTPCount = 0;
    this.sendOTP();
    this.serveSnackBar();
  }

  /** Method to validate the enter OTP */
  validateOTP() {
    this.ngxSpinner.show();
    this.userValidation.otp = this.regValidationForm.get('otp').value;
    this.userValidation.userId = this.userDetails.userId;
    if (this.appConfig.getConfig('byPassOtp')) {
      this.ngxSpinner.hide();
      let currentStep;
      currentStep = parseInt(sessionStorage.getItem('currentStep'), 10);
      this.notify.emit(currentStep);
    } else {
      this.ngxSpinner.hide();
      this.subscription.add(
        this.validationService
          .validateOTP(this.userValidation, true)
          .subscribe(response => {
            if (typeof(response) !== 'boolean') {
              this.ngxSpinner.hide();
              if(response?.error?.message?.toLowerCase().includes('otp validation attempts exceeded')) {
                this.regValidationForm.controls['otp'].setErrors({
                  exceededAttempts: true
                });
              } else {
                this.regValidationForm.controls['otp'].setErrors({
                  invalidResp: true
                })
              }
            } else if (response === false) {
              this.ngxSpinner.hide();
              this.invalidOTPCount = this.invalidOTPCount + 1;
              this.isContinueBtnEnable = this.invalidOTPCount >= 5 ? false : true;
              if (this.invalidOTPCount < 5) {
                this.regValidationForm.controls['otp'].setErrors({
                  invalidOTP: true
                });
              } else {
                this.regValidationForm.controls['otp'].setErrors({
                  exceededAttempts: true
                });
              }
            } else {
              this.ngxSpinner.hide();
              let currentStep;
              currentStep = parseInt(sessionStorage.getItem('currentStep'), 10);
                this.notify.emit(currentStep);
            }
          })
      );
    }
  }

  /** 
   * Method to check invalid otp count
   * Checks for form control validator and if missing sets back 
   */
  checkOTP() {
    if (!this.regValidationForm.controls['otp'].hasOwnProperty('required')) {
      this.regValidationForm.controls['otp'].setValidators(Validators.required)
    }
    if (this.invalidOTPCount >= 5) {
      this.regValidationForm.setErrors({ 'invalid': false });
    }
  }

  /**
   * To set error message to fields
   * @param fieldName - Field Name
   */
  getErrorMessage(fieldName): string | undefined {
    if (fieldName === 'OTP') {
      if (this.regValidationForm.get('otp').hasError('required')) {
        this.isContinueBtnEnable = false;
      }
      return this.regValidationForm.get('otp').hasError('required')
        ? 'Please enter a Security Code'
        : '';
    }
  }

  /** To Display Snack Bar when OTP is resent. */
  serveSnackBar() {
    let config: any = {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      data: 'Code Re-Sent to ',
      duration: 5000
    }

    // need to determine what method was sent before -- add a new global boolean variable like otpViaPhone
    config.data = this.showEmail ? `${config.data} Email` : `${config.data} Mobile #`
    this.snackBar.openFromComponent(CustomSnackbarComponent, config);
  }
}
