import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, of } from 'rxjs';
import { BaseClientService } from 'src/app/core/services/base-client.service';
import { catchError, map } from 'rxjs/operators';
import { RemoteLoggingService } from 'src/app/core/services/remote-logging.service';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagService {
    constructor(
        protected baseClient: BaseClientService,
        private readonly logSvc: RemoteLoggingService,
    ) { }

    loginAppFlag$: any = new BehaviorSubject<string>(null);
    anywhereAppFlag$: any = new BehaviorSubject<string>(null);
    costSimulatorAppFlag$: any = new BehaviorSubject<string>(null);

    async getLoginApp(value: string): Promise<boolean> {
        if (!this.loginAppFlag$.value) {
            const response = await firstValueFrom(this.getFeatureFlag(value));
            this.loginAppFlag$.next(response);
        }
        return Promise.resolve(this.loginAppFlag$.value && this.loginAppFlag$.value.toString() === 'true');
    }

    async getAnywhereApp(value: string): Promise<boolean> {
        if (!this.anywhereAppFlag$.value) {
            const response = await firstValueFrom(this.getFeatureFlag(value));
            this.anywhereAppFlag$.next(response);
        }
        return Promise.resolve(this.anywhereAppFlag$.value && this.anywhereAppFlag$.value.toString() === 'true');
    }

    async getCostSimulatorApp(value: string): Promise<boolean> {
        if (!this.costSimulatorAppFlag$.value) {
            const response = await firstValueFrom(this.getFeatureFlag(value));
            this.costSimulatorAppFlag$.next(response);
        }
        return Promise.resolve(this.costSimulatorAppFlag$.value && this.costSimulatorAppFlag$.value.toString() === 'true');
    }

    getFeatureFlag(name: string): Observable<any> {
        return this.baseClient.getById<boolean>('/featureFlag/' + name).pipe(
            map(r => r.body),
            catchError((err, source) => {
                const empty: boolean = null;
                this.logSvc.logError(err);
                return of(empty);
            })
        );
    }

}
