<mat-card fxLayout="column" fxLayoutAlign="center center" class="sb-card">
  <button class="unstyled-button card-button-cta" (click)="selectApp()">
    <img id="mobilifyHR-logo" *ngIf="application.name === 'mobilifyHR'" src="../../../../../assets/imgs/springboard/mobilifyHR.svg" class="sb-logo" alt="{{application.displayName}}">
    <img id="benefitsBuilder-logo" *ngIf="application.name === 'benefitsBuilder'" src="../../../../../assets/imgs/springboard/benefitsBuilder.svg" class="sb-logo" alt="{{application.displayName}}">
    <img id="movePro360-logo" *ngIf="application.name === 'movePro360'" src="../../../../../assets/imgs/springboard/movePro360.svg" class="sb-logo" alt="{{application.displayName}}">
    <img id="supplierPortal-logo" *ngIf="application.name === 'supplierPortal'" src="../../../../../assets/imgs/springboard/supplierPortal.svg" class="sb-logo" alt="{{application.displayName}}">
    <img id="cartusOnline-logo" *ngIf="application.name === 'cartusOnline'" src="../../../../../assets/imgs/springboard/cartusOnline.svg" class="sb-logo" alt="{{application.displayName}}">
    <img id="compensationServices-logo" *ngIf="application.name === 'compensationServices'" src="../../../../../assets/imgs/springboard/compensationservicesDummy.svg" class="sb-logo" alt="{{application.displayName}}">
    <img id="atlas-logo" *ngIf="application.name === 'atlas'" src="../../../../../assets/imgs/springboard/atlaslogo.svg" class="sb-logo" alt="{{application.displayName}}">
    <img id="cartusReporting-logo" *ngIf="application.name === 'atlasReporting'" src="../../../../../assets/imgs/springboard/cartusReporting.svg" class="sb-logo" alt="{{application.displayName}}">
    <img id="costSimulator-logo" *ngIf="application.name === 'costSimulator'" src="../../../../../assets/imgs/springboard/costSimulator.svg" class="sb-logo" alt="{{application.displayName}}">
    <h1>{{application.displayName}}</h1>
    <h2 *ngIf="application.subTitle">{{application.subTitle}}</h2>
    <h3>{{application.description}}</h3>
  </button>
</mat-card>
